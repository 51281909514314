import { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useI18nContext } from '@/i18n/i18n-react'
import { Auth } from 'aws-amplify'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import anime from 'animejs'
import { userInteractionsType } from '@/types/response/userInteractions'
import { useUserInteraction } from '@/hooks/useUserInteraction'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { useAppDispatch } from '@/store/hooks'
import { pushMessage } from '@/store/slice/messages'
import { useLandingWrapper } from './useLandingWrapper'
import FooterLanding from '../components/FooterLanding'
import HeaderLanding, { HeaderSubOptions } from '../components/HeaderLanding'
import StatusBar from '../components/StatusBar'
import Updates from '../components/Updates'
import GoToTop from '../components/GoToTop'
import Features from '../components/Features'
import VideoPopup from '../components/VideoPopup'
import { inview, setLinks, videoButtons } from '../utils'
import SignInHeader from '../../auth/SignInHeader'
import SignInFooterRedirect from '../../auth/SignInFooterRedirect'
import BenefitSlide from '../components/BenefitSlide'
import Testimonial from '../components/Testimonial'
import { VideoAurora } from '../../common/VideoAurora'

const LandingWrapper: FC = () => { 

    const { LL } = useI18nContext();
    const router = useRouter();
    const { user } = useAuthenticator(context => [context.route, context.user, context.authStatus]);

    const dispatch = useAppDispatch();

    const [redirectAfterLogin, setRedirectAfterLogin] = useState(false)
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const [errorLogin, setErrorLogin ] = useState('');

    const { toSignIn } = useAuthenticator();

    const { setItemUnderKey, getItemUnderKey } = useLocalStorage('OxionStorage')
    const isFirstTime = getItemUnderKey(userInteractionsType.LANDING_FIRST_VISIT)

    const { setBooleanInteraction } = useUserInteraction()

    const {
        state: { 
            benefitSlides,
            testimonials
        },
      } = useLandingWrapper()
    
    const isMobile = () => window.innerWidth <= 576;

    const checkIfFormExists = () => {
        const buttonLogin = document.querySelector('.amplify-button')
        if (buttonLogin) {
            setRedirectAfterLogin(true)
        }
    }

    const initAnimeJS = () => {
        const step1 = document.querySelector('#intro .step1');
        const step2 = document.querySelector('#intro .step2');
        
        const header = document.querySelector('.landing-oxion header');

        if(step1 && step2 && header) {

            const tl = anime.timeline({
                easing: 'easeInOutQuad',
                duration: 1200
            });

            if (!isFirstTime) {
                
                tl.add({
                    targets: step1,
                    changeComplete: () => {
                        step1.classList.add('active');
                    }
                })

                tl.add({
                    targets: step2,
                    changeComplete: () => {
                        step1.classList.remove('active');
                    }
                })

                setItemUnderKey(userInteractionsType.LANDING_FIRST_VISIT, true)
            }
        
            tl.add({
                targets: step2,
                changeComplete: () => {
                    step2.classList.add('active');
                }
            })
        
            tl.add({
                targets: header,
                changeComplete: () => {
                    header.classList.add('active');
                }
            })
        }
    }

    const setScrollMaging = () => {
        // eslint-disable-next-line prefer-destructuring, global-require, @typescript-eslint/no-require-imports
        const Splide = require('@splidejs/splide').Splide;
        // eslint-disable-next-line global-require, @typescript-eslint/no-require-imports
        const ScrollMagic = require('scrollmagic');
        
        // LOGO IGNION NN
        const controllerLogo = new ScrollMagic.Controller();
        const logo = document.querySelector('#leading .logo-created');
        
        new ScrollMagic.Scene({
            triggerElement: '.start-leading',
            offset: 50,
            triggerHook: 'onEnter',
        })
            .on('update', () => {
            })
            .on('enter', () => {
                if (logo) {
                    logo.classList.add('active');
                }
            })
            .on('leave', () => {
                if (logo) {
                    logo.classList.remove('active');
                }
            })
            .addTo(controllerLogo);

        const splide = new Splide('.slider', {
            direction: 'ttb',
            height: '100%',
            pagination: true,
            arrows: false
        });

        splide.mount();

        const splideMobile = new Splide('.slider-mobile', {
            pagination: true,
            arrows: false
        });

        splideMobile.mount();


        const controllerBenefit = new ScrollMagic.Controller();
        const whyOxion = document.querySelector('#benefits') as HTMLElement
        const elementHeight = whyOxion.offsetHeight ?? '1';
        const partHeight = elementHeight / 2;

        document.addEventListener('scrollWhy', () => {
            setTimeout( () => {
                controllerBenefit.scrollTo('#benefits');
            }, 500);
        }, false);

        let offset = 0;
        if ( isMobile() ) {
            offset = 80;
        }

        // Slide 1
        new ScrollMagic.Scene({
            triggerElement: '#benefits',
            triggerHook: 'onLeave',
            offset: 0 - offset,
            duration: partHeight
        })
            .on('enter', () => {
                splide.go(0); // go to slide 1
            })
            .setPin('#benefits')
            .addTo(controllerBenefit);

        // Slide 2
        new ScrollMagic.Scene({
            triggerElement: '#benefits',
            triggerHook: 'onLeave',
            offset: partHeight - offset,
            duration: partHeight
        })
            .on('enter', () => {
                splide.go(1); // go to slide 2
            })
            .setPin('#benefits')
            .addTo(controllerBenefit);

        // Slide 3
        new ScrollMagic.Scene({
            triggerElement: '#benefits',
            triggerHook: 'onLeave',
            offset: (partHeight * 2) - offset,
            duration: partHeight
        })
            .on('enter', () => {
                splide.go(2); // go to slide 3
            })
            .setPin('#benefits')
            .addTo(controllerBenefit);

        // Slide 4
        new ScrollMagic.Scene({
            triggerElement: '#benefits',
            triggerHook: 'onLeave',
            offset: (partHeight * 3) - offset,
            duration: partHeight
        })
            .on('enter', () => {
                splide.go(3); // go to slide 4
            })
            .setPin('#benefits')
            .addTo(controllerBenefit);

        if(window.innerWidth < 576) {
            controllerBenefit.destroy(true)
        }
    }

    const handleTestimonialHeight = () => {
        let maxHeight = 0;
        const allHeights = document.querySelectorAll<HTMLElement>('.landing-oxion #testimonials .testmonial .header')
        allHeights.forEach(element => {
            const height = element.clientHeight;
            if (height > maxHeight) {
                maxHeight = height;
            }
        });

        allHeights.forEach(element => element.style.height = `${maxHeight}px`)
    }

    const setTestimonials = () => {
        // eslint-disable-next-line prefer-destructuring, global-require, @typescript-eslint/no-require-imports
        const Splide = require('@splidejs/splide').Splide;

        const splide = new Splide('.slider-testimonials', {
            type: 'loop',
            padding: '35%',
            focus: 'center',
            arrows: true,
            pagination: true,
            breakpoints: {
                768: {
                    padding: '10%',
                    arrows: false
                },
                1200: {
                    padding: '25%',
                },
            },
        });

        splide.on( 'ready', () => {
            handleTestimonialHeight()
          } );

        splide.mount();
    }

    const components = {
        SignIn: {
          Header: SignInHeader,
          Footer: SignInFooterRedirect
        },
      };

    const formFields = {
        signIn: {
          username: {
            label: LL.signin.usernameLabel(),
            placeholder: LL.signin.usernamePlaceholder(),
            type: 'email',
          },
        },
        confirmSignIn: {
          confirmation_code: {
            label: LL.confirmSignIn.codeLabel(),
            placeholder: LL.confirmSignIn.codePlaceholder(),
            isRequired: true,
          },
        },
      }

      const optionsHeader:HeaderSubOptions[] = [
        {
            label: 'Features',
            href: '#features',
            active: false
        },
        {
            label: 'Benefits',
            href: '#benefits',
            active: false
        }
    ]

    useEffect(() => {
        document.documentElement.style.setProperty('--landing_FillAvailable', `${window.innerHeight}px`);

        setTimeout(checkIfFormExists, 4000);

        initAnimeJS()
        inview()
        setLinks()
        setScrollMaging()
        setTestimonials()
        videoButtons()
        
        toSignIn()
      }, []);

    useEffect(() => {
        if(user && redirectAfterLogin) {
            router.push('/dashboard', undefined, { shallow: true })
        }

        if(user) {
            setBooleanInteraction(userInteractionsType.LANDING_FIRST_VISIT)
        }
    }, [user, redirectAfterLogin]);

    return (
        <>
        <HeaderLanding options={optionsHeader} />

        <section id="intro" className="intro">
            <div className="background-video active">
                <VideoAurora />
            </div>

            <div className="step1 step-item">
                <img src="/images/landing/logo-intro.svg" alt="Oxion" width={1791} height={560}/>
            </div>

            <div className="step2 step-item">
                <div className="step2-left">
                    <h2 className="heading">Your next<br />successful product<br />begins here.</h2>
                    <p className="text">Antenna Intelligence Cloud™ is now part of Oxion™.</p>

                    <div className="logo-created">
                        <span>Created by</span>
                        <img src="/images/landing/logo-nn--white.svg" alt="Oxion" width={122} height={39} />
                    </div>
                </div>
                <div className="step2-right">
                    <div className='authenticator-form'>
                    <Authenticator initialState="signIn" loginMechanisms={['email']} formFields={formFields} components={components} 
                            services={{
                            async handleSignIn({ username, password, }) {
                                const p = Auth.signIn(username, password) 
                                p.catch((e) => {
                                    dispatch(pushMessage({ severity: 'error', summary: 'GENERIC_ERROR_CUSTOM_MESSAGE', detail: e.message }));
                                });

                                return p
                            },
                            async handleConfirmSignIn({ user, code, mfaType, }) {
                                const p = Auth.confirmSignIn(user, code, mfaType as 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | null | undefined) 
                                p.catch((e) => {
                                    dispatch(pushMessage({ severity: 'error', summary: 'GENERIC_ERROR_CUSTOM_MESSAGE', detail: e.message }));
                                });

                                return p
                            },
                            }}
                        />
                        <span className="Oxion-small-medium error-form">{errorLogin}</span>
                    </div>
                </div>
            </div>
        </section>

        <Features />

        <section id="benefits" className="why-oxion">
            <div className="why--wrapper">
                <div className="why--item left inview--fade-in">
                    <div className="background-video">
                        <VideoAurora />
                    </div>
                    <h3 className="title">BENEFITS</h3>
                    <h4 className="text">Designing wireless<br/>products has never<br/>been easier.</h4>
                    <div className="bg"><span /></div>
                </div>
                <div className="why--item right inview--fade-in">
                    <div className="background-video">
                        <VideoAurora />
                    </div>
                    <div className="slider splide slider-desktop">
                        <ul className="splide__pagination" />
                        <div className="splide__track">
                            <div className="splide__list">
                                {
                                    benefitSlides.map((benefit,index) => <BenefitSlide key={`${benefit.id}${benefit.title}-desktop`} id={`${benefit.id}${index}`} number={index+1} title={benefit.title} text={benefit.text} />)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="slider-mobile splide">
                        <ul className="splide__pagination" />
                        <div className="splide__track">
                            <div className="splide__list">
                                {
                                    benefitSlides.map((benefit,index) => <BenefitSlide key={`${benefit.id}${benefit.title}-mobile`} id={`${benefit.id}${index}`} number={index+1} title={benefit.title} text={benefit.text} />)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="bg"><span /></div>
                </div>
            </div>
        </section>

        <section id="claim" className="claim">
            <h3 className="text inview--fade-in">Oxion is the platform where you can transform your visionary ideas into real projects.</h3>

            <div className="button-video inview--fade-in">
                <a href="#" className="btn--video">Watch Video</a>
            </div>
        </section>

        <Updates />

        <section id="blue-block" className="blue-block">
            <div className="blue-block--wrapper">
                <h5 className="text inview--fade-in">We believe that all good ideas deserve easy access to connectivity. Antenna integration should not stand in the way of the next world-changing idea coming to life.</h5>

                <div className="steps inview--active delay-1">
                    <div className="item item-01"><span>Antenna integration demystified.</span></div>
                    <div className="item item-02"><span>Your idea from concept to marketplace reality.</span></div>
                    <div className="item item-03"><span>Empowering all with transparent access.</span></div>
                </div>
            </div>
        </section>

        <section id="testimonials" className="testimonials">
            <div className="testimonials--wrapper">

                <div className="slider-testimonials splide">
                    <ul className="splide__pagination" />
                    <div className="splide__arrows">
                        <button className="splide__arrow splide__arrow--prev" aria-label="Prev">

                            <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square" strokeLinejoin="round">
                                    <g id="Group-775" transform="translate(0.952700, 1.571500)" stroke="#000026" strokeWidth="1.40864">
                                        <line x1="1.77635684e-15" y1="8.0283" x2="16.4301" y2="8.1023" id="Path" />
                                        <polyline id="Path" points="9.0012 -1.77635684e-15 17.0324 8.104 8.9285 16.1352" />
                                    </g>
                                </g>
                            </svg>
                        </button>
                        <button className="splide__arrow splide__arrow--next" aria-label="Next">

                            <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square" strokeLinejoin="round">
                                    <g id="Group-775" transform="translate(0.952700, 1.571500)" stroke="#000026" strokeWidth="1.40864">
                                        <line x1="1.77635684e-15" y1="8.0283" x2="16.4301" y2="8.1023" id="Path" />
                                        <polyline id="Path" points="9.0012 -1.77635684e-15 17.0324 8.104 8.9285 16.1352" />
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>

                    <div className="splide__track">
                        <div className="splide__list">
                            {
                                testimonials.map((testimonial) => <Testimonial key={`${testimonial.person}-${testimonial.company}`} person={testimonial.person} company={testimonial.company} testimonial={testimonial.testimonial} color={testimonial.color} />)
                            }
                        </div>
                    </div>
                </div>



                <div className="background-video">
                    <VideoAurora />
                </div>
            </div>
        </section>

        <section id="leading" className="leading">
            <a target="_blank" href="https://ignion.io" className="logo-created" rel='noreferrer'>
                <span>Created by</span>
                <img src="/images/landing/logo-nn.svg" alt="Oxion" width={120} height={39} />
            </a>

            <div className="leading--wrapper inview--fade-in">
                <div className="left">
                    <p>Leading innovation in connectivity.</p>
                    <span className="logo-hide-me">Again.</span>
                </div>
                <div className="right">
                    <div className="text text-01">Removing barriers to IoT development is our driving force. That&apos;s why we&apos;ve dedicated ourselves to simplifying the use of Virtual Antenna® components through a powerful and user-friendly platform. <br />And this is just the beginning.</div>
                    <div className="text text-02">We are committed to shaping the future of connectivity for the better.<br />We want everyone, no matter where they are based and no matter what their level of technical expertise, to have access to our pioneering Virtual Antenna® technology. We believe in the power of brilliant ideas that have the potential to transform how we live. By making our Virtual Antenna® technology universally accessible, we can unleash the full potential of these ideas. Oxion is a space to make the greatest possible impact on the future of IoT.</div>
                    <a href="https://ignion.io" className="btn--blue_red start-leading" target="_blank" rel='noreferrer'>BEHIND THE PLATFORM</a>
                </div>
            </div>
            <div className="gallery fade-in">
                <div className="images images-01">
                    <div className="img delay-0_5 creators"><img src="/images/landing/leading-creators.png" alt="CREATORS" width={434} height={352} /> <span>CREATORS</span></div>
                    <div className="img delay-0_5 engineers"><img src="/images/landing/leading-engineers.png" alt="ENGINEERS" width={509} height={555} /> <span>ENGINEERS</span></div>
                </div>

                <div className="images images-02">
                    <div className="img delay-0_5 partners"><div><img src="/images/landing/leading-partners.png" alt="PARTNERS" width={434} height={538} /> <span>PARTNERS</span></div></div>
                    <div className="img delay-0_5 innovators"><img src="/images/landing/leading-innovators.png" alt="INNOVATORS" width={862} height={621} /> <span>INNOVATORS</span></div>
                </div>

            </div>
        </section>

        <FooterLanding />

        <GoToTop />

        <StatusBar />
        
        <VideoPopup />
        </>
    )
}


export default LandingWrapper