import { FC } from 'react'

type BenefitSlideProps = {
    id: string,
    number: number,
    title: string,
    text: string
}

const BenefitSlide: FC<BenefitSlideProps> = ({id, number, title, text}) => {    
    return (
        <div id={id} className="splide__slide slide">
            <div className="number">{number}</div>
            <div className="title" dangerouslySetInnerHTML={{__html: title}} />
            <div className="text" dangerouslySetInnerHTML={{__html: text}} />
        </div>
    )
}


export default BenefitSlide